"use client";

import { useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-thin-svg-icons";
import { useForm } from "react-hook-form";
import { Button } from "@/components/button";
import { cn } from "@/lib/utils";

export const SearchBox = ({ className, defaultValue }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onSubmit = async (values) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", 1);
    newParams.set("terms", values.terms);

    router.push(`/blog/?${newParams.toString()}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cn("my-5 p-1 overflow-hidden", className)}>
        <div className="md:flex items-center space-y-2 md:space-x-2 md:space-y-0">
          <div className="relative rounded-md basis-2/3">
            <input
              className="block w-full rounded-md border-0 py-2.5 pr-10 text-gray-950 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
              type="text"
              placeholder="Enter keywords..."
              {...register("terms", { required: true })}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon icon={faSearch} aria-hidden="true" />
            </div>
          </div>
          <div className="flex items-center basis-1/3 space-x-3">
            <Button className="w-full ml-auto" type="submit" variant="ghost">
              Search
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
