"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronsLeft,
  faChevronLeft,
  faChevronsRight,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

function getPageRange(pages, currentPage, range = 5) {
  const perSide = Math.floor((range - 1) / 2);
  const pagesArray = [...Array(pages).keys()].map((page) => page + 1);

  if (currentPage <= perSide) {
    return pagesArray.slice(0, range);
  } else if (currentPage > pages - perSide) {
    return pagesArray.slice(-range, pages);
  } else {
    return pagesArray.slice(currentPage - perSide - 1, currentPage + perSide);
  }
}

export const PostPagination = ({ pages, currentPage, terms = "" }) => {
  const pathname = usePathname();
  const pageRange = getPageRange(pages, currentPage);

  return (
    <ul className="text-gray-500 flex justify-center items-center space-x-2 my-12">
      {currentPage !== 1 && (
        <li>
          <Link
            className="flex justify-center items-center w-8 h-8 rounded-full hover:text-gray-950 hover:bg-gray-100"
            href={`${pathname}?page=1&terms=${terms}`}
          >
            <FontAwesomeIcon icon={faChevronsLeft} />
          </Link>
        </li>
      )}

      {currentPage > 1 && (
        <li>
          <Link
            className="text-gray-500 flex justify-center items-center w-8 h-8 mr-4 rounded-full hover:text-gray-950 hover:bg-gray-100"
            href={`${pathname}?page=${currentPage - 1}&terms=${terms}`}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        </li>
      )}

      {pageRange.map((page) => {
        return (
          <li key={page}>
            <Link
              className={cn(
                "text-gray-500 text-lg flex justify-center items-center w-8 h-8 rounded-full",
                page === currentPage
                  ? "text-white font-bold bg-blue-600"
                  : "hover:text-gray-950 hover:font-bold hover:bg-gray-100"
              )}
              href={`${pathname}?page=${page}&terms=${terms}`}
            >
              {page}
            </Link>
          </li>
        );
      })}

      {currentPage < pages && (
        <>
          <li>
            <Link
              className="text-gray-500 flex justify-center items-center w-8 h-8 ml-4 rounded-full hover:text-gray-950 hover:bg-gray-100"
              href={`${pathname}?page=${currentPage + 1}&terms=${terms}`}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </li>

          <li>
            <Link
              className="text-gray-500 flex justify-center items-center w-8 h-8 rounded-full hover:text-gray-950 hover:bg-gray-100"
              href={`${pathname}?page=${pages}&terms=${terms}`}
            >
              <FontAwesomeIcon icon={faChevronsRight} />
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};
